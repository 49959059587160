import React, { useState } from "react";
import { IoLockClosed, IoPerson } from "react-icons/io5";
import { useHistory } from "react-router";
import { useUser } from "../Context/UserProvider";
import { Encrypt, userLogin } from "./Service";
import CryptoJS from "crypto-js";
import toast, { Toaster } from "react-hot-toast";

export default function Login() {
  const { onSubmitUserHandler } = useUser();
  let history = useHistory();
  const [userDetails, setUserDetails] = useState({
    username: "",
    password: "",
  });

  const [isLoging, setIsLoging] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  const loginSubmitHandler = async (e) => {
    let password = userDetails.password;
    password = Encrypt(password);
    const { response, code } = await userLogin({
      username: userDetails.username,
      password,
    });
    if (code === 200) {
      toast.success("Login Success");
      localStorage.setItem("token", response?.token);
      console.log(response);
      localStorage.setItem("userId", JSON.stringify(response?.id));
      e.preventDefault();
      window.location.reload();
    } else if (code == 500) {
      toast.error(response);
    } else {
      toast.error("Login Failed");
    }
    /* localStorage.setItem("token", response?.token);
    e.preventDefault();
    window.location.reload(); */
  };

  return (
    <>
      <div className="login_wrapper">
        <div class="form_wrapper">
          <h1 class="auth-title">Log in.</h1>
          <p class="auth-subtitle mb-5">Admin Login</p>

          <div>
            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="text"
                class="form-control form-control-xl"
                placeholder="Username"
                name="username"
                onChange={handleChange}
              />
              <div class="form-control-icon">
                <IoPerson></IoPerson>
              </div>
            </div>
            <div class="form-group position-relative has-icon-left mb-4">
              <input
                type="password"
                class="form-control form-control-xl"
                placeholder="Password"
                name="password"
                onChange={handleChange}
              />
              <div class="form-control-icon">
                <IoLockClosed></IoLockClosed>
              </div>
            </div>

            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            <button
              class="btn btn-primary btn-block btn-lg shadow-lg mt-5"
              type="submit"
              disabled={isLoging}
              onClick={loginSubmitHandler}
            >
              Log in
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
